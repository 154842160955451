import { Patient } from '../../patient.types';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CallState, LoadingState } from '../../../common/call-state';
import { Action, createReducer, on } from '@ngrx/store';
import {
  loadPatients,
  loadPatientsFailed,
  loadPatientSucceeded
} from './patient.actions';

export const FEATURE_KEY = 'patient';

export const adapter: EntityAdapter<Patient> = createEntityAdapter<Patient>();

export interface State extends EntityState<Patient> {
  callState: CallState;
}

export const initialState: State = adapter.getInitialState({
  callState: LoadingState.INIT
});

const patientReducer = createReducer(
  initialState,
  on(loadPatients, (state) => ({
    ...state,
    callState: LoadingState.LOADING
  })),
  on(loadPatientSucceeded, (state, { patient }) =>
    adapter.setOne(patient, {
      ...state,
      callState: LoadingState.LOADED
    })
  ),
  on(loadPatientsFailed, (state, error) => ({
    ...state,
    callState: error
  }))
);

export function reducer(state: State | undefined, action: Action): State {
  return patientReducer(state, action);
}
