import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import { filter, take, withLatestFrom } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PracticeFacade } from './+state/practice/practice.facade';
import { Observable } from 'rxjs';
import { MediaMatcher } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loading$: Observable<boolean> = this.practiceGuidFacade.loading$;
  loaded$: Observable<boolean> = this.practiceGuidFacade.loaded$;

  constructor(
    private readonly router: Router,
    private readonly practiceGuidFacade: PracticeFacade,
    private readonly mediaMatcher: MediaMatcher,
    private readonly renderer: Renderer2,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  ngOnInit(): void {
    this.updateFavicon();
    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof ActivationStart &&
            !event.snapshot.data?.dontLoadPractice
        ),
        take(1)
      )
      .subscribe((routerEvent: ActivationStart) => {
        if (!routerEvent.snapshot.params.practice_guid) {
          return;
        }

        this.practiceGuidFacade.loadPractice(
          routerEvent.snapshot.params.practice_guid
        );
      });

    this.practiceGuidFacade.error$
      .pipe(
        untilDestroyed(this),
        filter((error) => !!error),
        withLatestFrom(this.practiceGuidFacade.practiceGuid$)
      )
      .subscribe(([error, practiceGuid]) => {
        this.router.navigate([`${practiceGuid}/not-found`]);
      });
  }

  private updateFavicon(): void {
    const darkThemeMatcher = this.mediaMatcher.matchMedia(
      '(prefers-color-scheme: dark)'
    );
    const lightSchemeIcon = this.document.querySelector(
      'link#favicon-light'
    ) as HTMLElement;
    const darkSchemeIcon = this.document.querySelector(
      'link#favicon-dark'
    ) as HTMLElement;

    if (darkThemeMatcher.matches) {
      this.renderer.removeChild(this.document.head, lightSchemeIcon);
      this.renderer.appendChild(this.document.head, darkSchemeIcon);
    } else {
      this.renderer.removeChild(this.document.head, darkSchemeIcon);
      this.renderer.appendChild(this.document.head, lightSchemeIcon);
    }
  }
}
