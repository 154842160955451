import { Inject, Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginWithTokenResponse, LogoutResponse } from '../login.types';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIGURATION, AppConfiguration } from '../../app.types';
import { createHttpClientWithInterceptors } from '../../common/http-client-interceptors/custom-http-client/http-client.factory';
import { ErrorInterceptor } from '../../common/http-client-interceptors/error.interceptor';

@Injectable({
  providedIn: 'root'
})
export class OAuthService {
  static TOKEN_WITH_CODE = 'oauth/token-with-code';
  static TOKEN = 'oauth/token';
  static LOGOUT = 'oauth/logout';

  private httpClient: HttpClient;

  constructor(
    @Inject(APP_CONFIGURATION) private readonly config: AppConfiguration,
    private readonly injector: Injector
  ) {
    this.httpClient = createHttpClientWithInterceptors(this.injector, [
      ErrorInterceptor
    ]);
  }

  loginWithToken(
    email: string,
    token: string
  ): Observable<LoginWithTokenResponse> {
    return this.httpClient.post<LoginWithTokenResponse>(
      `${this.config.apiUrl}/${OAuthService.TOKEN_WITH_CODE}`,
      {
        email,
        registration_token: token
      }
    );
  }

  loginWithPassword(
    email: string,
    password: string,
    practiceGuid: string
  ): Observable<LoginWithTokenResponse> {
    return this.httpClient.post<LoginWithTokenResponse>(
      `${this.config.apiUrl}/${OAuthService.TOKEN}`,
      {
        grant_type: 'password',
        client_id: this.config.clientId,
        client_secret: this.config.clientSecret,
        username: email,
        password,
        practice_guid: practiceGuid
      }
    );
  }

  logout(): Observable<LogoutResponse> {
    return this.httpClient.get<LogoutResponse>(
      `${this.config.apiUrl}/${OAuthService.LOGOUT}`
    );
  }
}
