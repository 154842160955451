import { HttpErrorResponse } from '@angular/common/http';

export enum LoadingState {
  INIT = 'INIT',
  LOADING = 'LOADING',
  LOADED = 'LOADED'
}

export interface ErrorState {
  message: string;
  code: number;
}

export type CallState = LoadingState | ErrorState;

export const getErrorState = (error: HttpErrorResponse): ErrorState => {
  return {
    message: error.error?.message,
    code: error.status
  };
};
