import { Inject, Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_CONFIGURATION, AppConfiguration } from '../../app.types';

@Injectable()
export class ApiUrlInterceptor implements HttpInterceptor {
  constructor(
    @Inject(APP_CONFIGURATION) private readonly config: AppConfiguration
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const requestWithApiUrl = request.clone({
      url: `${this.config.apiUrl}/${request.url}`
    });

    return next.handle(requestWithApiUrl);
  }
}
