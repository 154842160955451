import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { State } from './patient.reducer';
import { loadPatients } from './patient.actions';
import { Observable } from 'rxjs';
import { Patient } from '../../patient.types';
import { selectAll, selectLoaded, selectLoading } from './patient.selectors';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PatientFacade {
  patients$: Observable<Patient[]> = this.store.pipe(
    select(selectAll),
    filter((patients) => !!patients)
  );
  currentPatient$: Observable<Patient> = this.patients$.pipe(
    map((patients) => patients[0])
  );
  loading$: Observable<boolean> = this.store.pipe(select(selectLoading));
  loaded$: Observable<boolean> = this.store.pipe(select(selectLoaded));

  constructor(private readonly store: Store<State>) {}

  loadPatients(): void {
    this.store.dispatch(loadPatients());
  }
}
