import { createAction, props } from '@ngrx/store';
import { Patient } from '../../patient.types';
import { getErrorState } from '../../../common/call-state';

export const loadPatients = createAction('[Portal/Patient] Load patients');

export const loadPatientSucceeded = createAction(
  '[Portal/Patient] Load patient succeeded',
  props<{ patient: Patient }>()
);

export const loadPatientsFailed = createAction(
  '[Portal/Patient] Load patients failed',
  getErrorState
);
