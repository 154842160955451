import { Inject, Injectable } from '@angular/core';
import { UserData } from './user-data.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { APP_CONFIGURATION, AppConfiguration } from '../../app.types';
import { OAuthService } from '../../login/data-access/oauth.service';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  userLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  userData: UserData | null = null;
  readonly userDataLocalStorageKey: string = 'currentPatientUser';

  constructor(
    private readonly httpClient: HttpClient,
    private readonly oauthService: OAuthService,
    @Inject(APP_CONFIGURATION) private readonly appConfig: AppConfiguration
  ) {}

  setUserData(
    username: string,
    accessToken: string,
    isActive: boolean = false
  ): void {
    this.userData = {
      username,
      accessToken,
      isActive
    };

    if (this.appConfig.keepUserSession) {
      localStorage.setItem(
        this.userDataLocalStorageKey,
        JSON.stringify(this.userData)
      );
    }

    this.userLoggedIn$.next(true);
  }

  isUserLoggedIn(): boolean {
    return this.userLoggedIn$.value;
  }

  loadUserData(): void {
    const storedUserData = localStorage.getItem(this.userDataLocalStorageKey);

    if (!storedUserData) {
      return;
    }

    this.userData = JSON.parse(storedUserData);
    this.userLoggedIn$.next(true);
  }

  createMobileSession(authToken: string): void {
    this.userData = {
      accessToken: authToken,
      username: 'mobile',
      isActive: true
    };

    this.userLoggedIn$.next(true);
  }

  logout(): Observable<boolean> {
    return this.oauthService.logout().pipe(
      tap((response) => {
        if (response.success) {
          this.clearStoredData();
        }
      }),
      map((response) => response.success)
    );
  }

  clearStoredData(): void {
    localStorage.removeItem(this.userDataLocalStorageKey);

    this.userLoggedIn$.next(false);
  }
}
