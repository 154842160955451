import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsUserLoggedInGuard } from './is-user-logged-in.guard';

const routes: Routes = [
  {
    path: ':practice_guid/sign-up',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule)
  },
  {
    path: ':practice_guid/not-found',
    loadChildren: () =>
      import('./practice-not-found/practice-not-found.module').then(
        (m) => m.PracticeNotFoundModule
      ),
    data: {
      dontLoadPractice: true
    }
  },
  {
    path: ':practice_guid/m/:auth_token',
    loadChildren: () =>
      import('./mobile/mobile.module').then((m) => m.MobileModule)
  },
  {
    path: ':practice_guid',
    canActivate: [IsUserLoggedInGuard],
    loadChildren: () =>
      import('./patient/patient.module').then((m) => m.PatientModule)
  },
  {
    path: '**',
    loadChildren: () =>
      import('./practice-not-found/practice-not-found.module').then(
        (m) => m.PracticeNotFoundModule
      ),
    data: {
      dontLoadPractice: true
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
