export interface Practice {
  guid: string;
  name: string;
  logo: string;
  big_logo: string;
  address: string;
  phone: string;
  email: string;
  latitude: string;
  longitude: string;
  availability: string;
  direct_email: string;
  www_url: string;
  fb_url: string;
  yelp_url: string;
  google_url: string;
  foursquare_url: string;
  fb_review_url: string;
  yelp_review_url: string;
  app_url: string;
  published: boolean;
  patient_portal: string;
  payments_operator: PaymentsOperator;
  payments_patient_portal_enabled: boolean;
  balance_patient_portal_enabled: boolean;
}

export enum PaymentsOperator {
  CLEARENT = 'payments_clearent'
}

export type FeatureFlag = '1' | null;
