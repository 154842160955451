import {
  HttpBackend,
  HttpClient,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injector, Type } from '@angular/core';
import { HttpInterceptingIncludeHandler } from './http-intercepting-include-handler';
import { HttpInterceptingExcludeHandler } from './http-intercepting-exclude-handler';

export function createHttpClientWithExcludedInterceptors(
  injector: Injector,
  excludedInterceptors: Type<HttpInterceptor>[],
  intercept?: (req: HttpRequest<any>) => HttpRequest<any>
): HttpClient {
  return new HttpClient(
    new HttpInterceptingExcludeHandler(
      injector.get(HttpBackend),
      injector,
      excludedInterceptors,
      intercept
    )
  );
}

export function createHttpClientWithInterceptors(
  injector: Injector,
  interceptors: Type<HttpInterceptor>[],
  intercept?: (req: HttpRequest<any>) => HttpRequest<any>
): HttpClient {
  return new HttpClient(
    new HttpInterceptingIncludeHandler(
      injector.get(HttpBackend),
      injector,
      interceptors,
      intercept
    )
  );
}
