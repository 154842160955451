import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserDataService } from './common/user/user-data.service';

@Injectable({
  providedIn: 'root'
})
export class IsUserLoggedInGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly userDataService: UserDataService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return (
      this.userDataService.isUserLoggedIn() ||
      this.router.parseUrl(`/${route.params.practice_guid}/sign-up`)
    );
  }
}
