import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter, FEATURE_KEY, State } from './patient.reducer';
import { LoadingState } from '../../../common/call-state';

const selectState = createFeatureSelector<State>(FEATURE_KEY);

const adapterSelectors = adapter.getSelectors();

export const selectAll = createSelector(
  selectState,
  adapterSelectors.selectAll
);

export const selectLoading = createSelector(
  selectState,
  (state: State) => state.callState === LoadingState.LOADING
);

export const selectLoaded = createSelector(
  selectState,
  (state: State) => state.callState === LoadingState.LOADED
);
