import { Inject, Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { PracticeFacade } from './+state/practice/practice.facade';
import { concatMap, take } from 'rxjs/operators';
import { APP_CONFIGURATION, AppConfiguration } from './app.types';

@Injectable()
export class PracticeGuidInterceptor implements HttpInterceptor {
  constructor(
    private readonly practiceFacade: PracticeFacade,
    @Inject(APP_CONFIGURATION) private readonly config: AppConfiguration
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return !request.url.endsWith('practice')
      ? this.practiceFacade.practice$.pipe(
          take(1),
          concatMap(({ guid }) => {
            return next.handle(
              this.prepareRequestWithPracticeGuid(guid, request)
            );
          })
        )
      : this.practiceFacade.practiceGuid$.pipe(
          take(1),
          concatMap((practiceGuid) => {
            return next.handle(
              this.prepareRequestWithPracticeGuid(practiceGuid, request)
            );
          })
        );
  }

  private prepareRequestWithPracticeGuid(
    practiceGuid: string,
    request: HttpRequest<any>
  ): HttpRequest<any> {
    return request.clone({
      url: request.url.startsWith(this.config.apiUrl)
        ? request.url.replace(
            this.config.apiUrl,
            `${this.config.apiUrl}/${practiceGuid}`
          )
        : `${practiceGuid}/${request.url}`
    });
  }
}
