import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  HttpBackend,
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UserDataService } from './common/user';
import * as practice from './+state/practice/practice.reducer';
import { PracticeEffects } from './+state/practice/practice.effects';
import { HttpClientInterceptorsModule } from './common/http-client-interceptors/http-client-interceptors.module';
import { LoaderModule } from './loader/loader.module';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PracticeGuidInterceptor } from './practice-guid.interceptor';
import { ReactiveComponentModule } from '@ngrx/component';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { VERSION } from '../environments/version';
import { environment } from '../environments/environment';
import { PracticeFacade } from './+state/practice/practice.facade';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { PatientFacade } from './patient/+state/patient/patient.facade';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DOCUMENT } from '@angular/common';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    StoreModule.forRoot({ [practice.FEATURE_KEY]: practice.reducer }),
    EffectsModule.forRoot([PracticeEffects]),
    StoreDevtoolsModule.instrument(),
    HttpClientInterceptorsModule,
    LoaderModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (httpBackend) => createTranslateLoader(httpBackend),
        deps: [HttpBackend]
      }
    }),
    ReactiveComponentModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (userDataService, practiceFacade, patientFacade) =>
        initApplication(userDataService, practiceFacade, patientFacade),
      multi: true,
      deps: [UserDataService, PracticeFacade, PatientFacade]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PracticeGuidInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({})
    },
    {
      provide: MatIconRegistry,
      useFactory: (
        httpBackend: HttpBackend,
        domSanitizer: DomSanitizer,
        document: Document
      ) =>
        new MatIconRegistry(
          new HttpClient(httpBackend),
          domSanitizer,
          document,
          {
            handleError: () => {}
          }
        ),
      deps: [HttpBackend, DomSanitizer, DOCUMENT]
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        disableClose: true,
        autoFocus: false,
        hasBackdrop: true
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(translate: TranslateService) {
    translate.setDefaultLang('en');
    translate.use('en');
  }
}

const initApplication = (
  userDataService: UserDataService,
  practiceFacade: PracticeFacade,
  patientFacade: PatientFacade
): (() => Promise<boolean>) => {
  return () =>
    new Promise((resolve, reject) => {
      Sentry.init({
        enabled: environment.sentry.enabled,
        dsn: environment.sentry.DSN,
        release: `modento-portal@${VERSION.version}-${VERSION.hash}`,
        environment: environment.name,
        autoSessionTracking: true,
        integrations: [
          new Integrations.BrowserTracing({
            tracingOrigins: ['localhost', environment.apiUrl],
            routingInstrumentation: Sentry.routingInstrumentation
          })
        ],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
        beforeSend: (event, hint) => {
          return practiceFacade.practiceGuid$
            .pipe(
              withLatestFrom(patientFacade.patients$),
              take(1),
              map(([practiceGuid, patients]) => {
                if (!event.tags) {
                  event.tags = {};
                }

                event.tags.practice_guid = practiceGuid;

                if (!event.extra) {
                  event.extra = {};
                }

                if (patients.length) {
                  event.extra.patient_id = patients[0].id;
                  event.extra.user_id = patients[0].user_id;
                }

                return event;
              })
            )
            .toPromise();
        }
      });

      userDataService.loadUserData();
      resolve(null);
    });
};

const createTranslateLoader = (handler: HttpBackend): TranslateHttpLoader => {
  return new TranslateHttpLoader(new HttpClient(handler));
};
