import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { UserDataService } from '../user';
import { CustomHttpParams } from './custom-http-client/custom-http-params';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private readonly connectionFailedError: string = this.translateService.instant(
    'http-client-interceptors.error-interceptor.connection-failed'
  );
  private readonly unknownErrorMessage: string = this.translateService.instant(
    'http-client-interceptors.error-interceptor.unknown'
  );

  constructor(
    private readonly matSnackBar: MatSnackBar,
    private readonly translateService: TranslateService,
    private readonly userDataService: UserDataService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMessage = this.getErrorMessage(error);

        if (!this.hideErrorSnackBar(request)) {
          this.matSnackBar.open(errorMessage, null, {
            duration: 5000,
            verticalPosition: window.innerWidth < 768 ? 'bottom' : 'top',
            horizontalPosition: window.innerWidth < 768 ? 'center' : 'right'
          });
        }

        switch (error.status) {
          case 401:
          case 403:
            this.userDataService.clearStoredData();
            break;
          default:
            break;
        }

        error.error.message = errorMessage;

        return throwError(error);
      })
    );
  }

  private getErrorMessage(error: HttpErrorResponse): string {
    if (!navigator.onLine) {
      return this.connectionFailedError;
    }

    if (error.error && error.error.errors) {
      const serverErrors = Object.keys(error.error.errors).map(
        (x) => error.error.errors[x]
      );
      return serverErrors[0].join('<br>');
    } else if (
      error.error &&
      error.error.message &&
      error.error.message !== ''
    ) {
      return error.error.message;
    } else {
      return this.unknownErrorMessage;
    }
  }

  private hideErrorSnackBar(request: HttpRequest<any>): boolean {
    return (
      request.params instanceof CustomHttpParams &&
      request.params.customParams.hideToastError
    );
  }
}
