import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector
} from '@ngrx/store';
import { FEATURE_KEY, State } from './practice.reducer';
import { LoadingState } from '../../common/call-state';

const featureSelector = createFeatureSelector<State>(FEATURE_KEY);

export const selectPracticeGuid = createSelector(
  featureSelector,
  (state: State) => state.practiceGuid
);

export const selectPractice = createSelector(
  featureSelector,
  (state: State) => state.practice
);

export const selectLoading = createSelector(
  featureSelector,
  (state: State) => state.callState === LoadingState.LOADING
);

export const selectLoaded = createSelector(
  featureSelector,
  (state: State) => state.callState === LoadingState.LOADED
);

export const selectError = createSelector(featureSelector, (state: State) => {
  return typeof state.callState !== 'object' ? null : state.callState;
});
