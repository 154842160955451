import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserDataService } from '../user';

@Injectable()
export class AuthorizationHeaderInterceptor implements HttpInterceptor {
  static AUTHORIZATION_HEADER_KEY = 'Authorization';

  constructor(private readonly userDataService: UserDataService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (this.userDataService.isUserLoggedIn()) {
      const requestWithAuthorizationHeaders = request.clone({
        headers: request.headers.set(
          AuthorizationHeaderInterceptor.AUTHORIZATION_HEADER_KEY,
          `Bearer ${this.userDataService.userData.accessToken}`
        )
      });

      return next.handle(requestWithAuthorizationHeaders);
    }

    return next.handle(request);
  }
}
