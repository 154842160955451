import { InjectionToken } from '@angular/core';
import { environment } from '../environments/environment';
import { VERSION } from '../environments/version';

export interface AppConfiguration {
  serverUrl: string;
  apiUrl: string;
  clientId: number;
  clientSecret: string;
  formsAppUrl: string;
  development: {
    replaceDevFormWithLocalhost: boolean;
  };
  version: {
    dirty: boolean;
    raw: string;
    hash: string;
    distance: string;
    tag: string;
    semver: string;
    suffix: string;
    semverString: string;
    version: string;
  };
  keepUserSession: boolean;
}

export const APP_CONFIGURATION = new InjectionToken<AppConfiguration>(
  'APP_CONFIG',
  {
    factory: () => ({
      serverUrl: environment.serverUrl,
      clientSecret: environment.clientSecret,
      clientId: environment.clientId,
      apiUrl: environment.apiUrl,
      formsAppUrl: environment.formsAppUrl,
      development: {
        replaceDevFormWithLocalhost:
          environment.development.replaceDevFormWithLocalhost
      },
      version: VERSION,
      keepUserSession: environment.keepUserSession
    })
  }
);
