import { HttpParams } from '@angular/common/http';

export class CustomHttpParams extends HttpParams {
  constructor(
    public customParams: { hideToastError?: boolean },
    params?: { [param: string]: string | string[] }
  ) {
    super({ fromObject: params });
  }
}
