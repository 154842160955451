export const environment = {
  name: 'dev',
  production: false,
  serverUrl: 'https://dev.modento.io',
  apiUrl: 'https://dev.modento.io/api/v1',
  clientId: 3,
  clientSecret: 'qW05Jb4XhReILCOhlw9wLiiTtFTBrkQD2asNTPt1',
  sentry: {
    enabled: true,
    DSN:
      'https://896ffadd768e48e9b7fa1471d0e74803@o109559.ingest.sentry.io/5574960'
  },
  formsAppUrl: 'https://dev-forms.modento.io',
  development: {
    replaceDevFormWithLocalhost: false
  },
  keepUserSession: false,
  clearent: {
    sandboxEnabled: true
  }
};
